/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef ,useEffect} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Model({playaim}) {
  console.log(playaim)
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('https://3d.mikuos.com/public/models/island.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    if(playaim){
      actions.one.play();
      actions.tow.play();
      actions.three.play();
    }
  });
  return (
    <group 
    rotation={[0, -1.9, 0]}
    ref={group} dispose={null}>
   <group name="Scene">
        <mesh name="Balls0" geometry={nodes.Balls0.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls1" geometry={nodes.Balls1.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls10" geometry={nodes.Balls10.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls11" geometry={nodes.Balls11.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls12" geometry={nodes.Balls12.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls13" geometry={nodes.Balls13.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls14" geometry={nodes.Balls14.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls2" geometry={nodes.Balls2.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls3" geometry={nodes.Balls3.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls4" geometry={nodes.Balls4.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls5" geometry={nodes.Balls5.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls6" geometry={nodes.Balls6.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls61" geometry={nodes.Balls61.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls62" geometry={nodes.Balls62.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls7" geometry={nodes.Balls7.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls8" geometry={nodes.Balls8.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls9" geometry={nodes.Balls9.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Balls91" geometry={nodes.Balls91.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="st" geometry={nodes.st.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar2" geometry={nodes.BigStar2.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar3" geometry={nodes.BigStar3.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar4" geometry={nodes.BigStar4.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar5" geometry={nodes.BigStar5.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar6" geometry={nodes.BigStar6.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar7" geometry={nodes.BigStar7.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="BigStar8" geometry={nodes.BigStar8.geometry} material={materials.Light} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <group name="BoxGift" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxGift_1" geometry={nodes.BoxGift_1.geometry} material={materials.Ice} />
          <mesh name="BoxGift_2" geometry={nodes.BoxGift_2.geometry} material={materials.Red} />
        </group>
        <group name="BoxGift1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxGift1_1" geometry={nodes.BoxGift1_1.geometry} material={materials.Orange} />
          <mesh name="BoxGift1_2" geometry={nodes.BoxGift1_2.geometry} material={materials['default']} />
        </group>
        <group name="BoxGift2" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxGift2_1" geometry={nodes.BoxGift2_1.geometry} material={materials.Blue} />
          <mesh name="BoxGift2_2" geometry={nodes.BoxGift2_2.geometry} material={materials.Violet} />
        </group>
        <group name="BoxGift3" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxGift3_1" geometry={nodes.BoxGift3_1.geometry} material={materials.Orange} />
          <mesh name="BoxGift3_2" geometry={nodes.BoxGift3_2.geometry} material={materials.Blue} />
        </group>
        <group name="BoxGift4" position={[0, -1.26, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxGift4_1" geometry={nodes.BoxGift4_1.geometry} material={materials.Ice} />
          <mesh name="BoxGift4_2" geometry={nodes.BoxGift4_2.geometry} material={materials.Blue} />
          <mesh name="BoxGift4_3" geometry={nodes.BoxGift4_3.geometry} material={materials.Orange} />
        </group>
        <group name="BoxSmall" position={[0, 0.45, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxSmall_1" geometry={nodes.BoxSmall_1.geometry} material={materials.Red} />
          <mesh name="BoxSmall_2" geometry={nodes.BoxSmall_2.geometry} material={materials.Blue} />
        </group>
        <group name="BoxSmall1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxSmall1_1" geometry={nodes.BoxSmall1_1.geometry} material={materials.Red} />
          <mesh name="BoxSmall1_2" geometry={nodes.BoxSmall1_2.geometry} material={materials.Blue} />
        </group>
        <group name="BoxSmall3" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="BoxSmall3_1" geometry={nodes.BoxSmall3_1.geometry} material={materials.Red} />
          <mesh name="BoxSmall3_2" geometry={nodes.BoxSmall3_2.geometry} material={materials.Blue} />
        </group>
        <mesh name="ChristmasTree" geometry={nodes.ChristmasTree.geometry} material={materials.Tree} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <group name="Door1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Door1_1" geometry={nodes.Door1_1.geometry} material={materials.Red} />
          <mesh name="Door1_2" geometry={nodes.Door1_2.geometry} material={materials.Orange} />
        </group>
        <group name="Home" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Home_1" geometry={nodes.Home_1.geometry} material={materials['Wood.1']} />
          <mesh name="Home_2" geometry={nodes.Home_2.geometry} material={materials.Red} />
        </group>
        <group name="Island1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Island1_1" geometry={nodes.Island1_1.geometry} material={materials['Mount.2']} />
          <mesh name="Island1_2" geometry={nodes.Island1_2.geometry} material={materials.Ice} />
        </group>
        <mesh name="Rain2" geometry={nodes.Rain2.geometry} material={materials.Ice} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <mesh name="Rain3" geometry={nodes.Rain3.geometry} material={materials.Red} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <group name="Rain4" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Rain4_1" geometry={nodes.Rain4_1.geometry} material={materials['default']} />
          <mesh name="Rain4_2" geometry={nodes.Rain4_2.geometry} material={materials.Ice} />
        </group>
        <mesh name="Roof" geometry={nodes.Roof.geometry} material={materials.Red} rotation={[Math.PI / 2, 0, 0]} scale={0.01} />
        <group name="Roof2" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Roof2_1" geometry={nodes.Roof2_1.geometry} material={materials['default']} />
          <mesh name="Roof2_2" geometry={nodes.Roof2_2.geometry} material={materials.Red} />
        </group>
        <group name="Snowwoman1" position={[-9.17, 0, 10.65]} rotation={[Math.PI / 2, 0, -0.97]} scale={0.01}>
          <mesh name="Snowwoman1_1" geometry={nodes.Snowwoman1_1.geometry} material={materials.Orange} />
          <mesh name="Snowwoman1_2" geometry={nodes.Snowwoman1_2.geometry} material={materials.Ice} />
          <mesh name="Snowwoman1_3" geometry={nodes.Snowwoman1_3.geometry} material={materials.Red} />
        </group>
        <group name="Tree" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree_1" geometry={nodes.Tree_1.geometry} material={materials.Tree} />
          <mesh name="Tree_2" geometry={nodes.Tree_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree1_1" geometry={nodes.Tree1_1.geometry} material={materials.Tree} />
          <mesh name="Tree1_2" geometry={nodes.Tree1_2.geometry} material={materials['default']} />
        </group>
        <group name="Tree2" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree2_1" geometry={nodes.Tree2_1.geometry} material={materials.Tree} />
          <mesh name="Tree2_2" geometry={nodes.Tree2_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree3" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree3_1" geometry={nodes.Tree3_1.geometry} material={materials.Tree} />
          <mesh name="Tree3_2" geometry={nodes.Tree3_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree4" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree4_1" geometry={nodes.Tree4_1.geometry} material={materials.Tree} />
          <mesh name="Tree4_2" geometry={nodes.Tree4_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree5" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree5_1" geometry={nodes.Tree5_1.geometry} material={materials.Tree} />
          <mesh name="Tree5_2" geometry={nodes.Tree5_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree6" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree6_1" geometry={nodes.Tree6_1.geometry} material={materials.Tree} />
          <mesh name="Tree6_2" geometry={nodes.Tree6_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree7" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree7_1" geometry={nodes.Tree7_1.geometry} material={materials.Tree} />
          <mesh name="Tree7_2" geometry={nodes.Tree7_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree8" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree8_1" geometry={nodes.Tree8_1.geometry} material={materials.Tree} />
          <mesh name="Tree8_2" geometry={nodes.Tree8_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Tree9" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Tree9_1" geometry={nodes.Tree9_1.geometry} material={materials.Tree} />
          <mesh name="Tree9_2" geometry={nodes.Tree9_2.geometry} material={materials['Wood.1']} />
        </group>
        <group name="Window" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Window_1" geometry={nodes.Window_1.geometry} material={materials.Light} />
          <mesh name="Window_2" geometry={nodes.Window_2.geometry} material={materials['default']} />
        </group>
        <group name="Window1" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <mesh name="Window1_1" geometry={nodes.Window1_1.geometry} material={materials.Light} />
          <mesh name="Window1_2" geometry={nodes.Window1_2.geometry} material={materials['default']} />
        </group>
        <mesh name="Snowwoman003" geometry={nodes.Snowwoman003.geometry} material={materials['材质.001']} position={[-10.22, 1.42, 10.62]} rotation={[Math.PI / 2, 0, -0.7]} scale={0.01} />
        <mesh name="Snow" geometry={nodes.Snow.geometry} material={materials.Ice} position={[0, 24.5, 0]} rotation={[Math.PI / 2, 0, 0.25]} scale={0.01} />
        <mesh name="Snow001" geometry={nodes.Snow001.geometry} material={materials['Ice.009']} position={[0, 88.77, 0]} rotation={[Math.PI / 2, 0, 0.25]} scale={0.01} />
        <mesh name="Snow002" geometry={nodes.Snow002.geometry} material={materials['Ice.010']} position={[0, -42.47, 0]} rotation={[Math.PI / 2, 0, 0.25]} scale={0.01} />
        <mesh name="文本001" geometry={nodes.文本001.geometry} material={materials['材质.004']} position={[-17.8, -4.86, -1.38]} rotation={[1.44, -0.33, 1.18]} scale={[2.49, 1.72, 2.75]} />
        <mesh name="立方体" geometry={nodes.立方体.geometry} material={materials['材质.003']} position={[-14.36, -4.19, 6.14]} rotation={[-3.13, -0.37, 0.38]} scale={[-0.25, -1.44, -9.34]} />
        <mesh name="Snowwoman004" geometry={nodes.Snowwoman004.geometry} material={materials['材质.005']} position={[-9.93, 1.37, 11.39]} rotation={[Math.PI / 2, 0, -0.7]} scale={0.01} />
      </group>
    </group>
)
}

useGLTF.preload('https://3d.mikuos.com/public/models/island.glb')