import { Html, useProgress} from '@react-three/drei'
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

export default function Loader() {
    const { progress} = useProgress();
    //console.log(loaded)
    return (
    <Html>
        <Box 
        className="loadingloop"
        sx={{ width: '100%' }}>
        <CircularProgressWithLabel value={parseInt(progress)} />
        <p class="loading">礼物正在加载中...</p>
        </Box>
    </Html>
    );
  }
  //<p class="loading">惊喜正在加载... {parseInt(progress)}%</p>