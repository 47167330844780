import './App.css';
import './components/web'
import Webpack from './components/web';

function App() {

  return (
  
    <div class="webindex">
   <Webpack></Webpack>
   </div>
  );
}

export default App;
