//import { useLoader } from '@react-three/fiber'
//import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
//import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader'
import React from 'react'
import { useGLTF } from '@react-three/drei'
//import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
//import { useAnimations } from "@react-three/drei";


export function ModelKuromi(props) {
  const gltf = useGLTF('https://3d.mikuos.com/public/models/Kuromi.gltf'); // 模型路径
  return (
    <mesh>
    <primitive
    rotation={[0, -3, 0]}
    position={[9,-2.5,-9]}
    object={gltf.scene} />
    </mesh>
    
  );
}
useGLTF.preload('https://3d.mikuos.com/public/models/Kuromi.gltf'); // 预加载模型
