import React,{useState,Suspense} from "react";
import { Canvas} from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
//import { suspend } from 'suspend-react'

//import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
//import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
//import { useLoader } from '@react-three/fiber'
import { PerspectiveCamera , Environment,Html} from '@react-three/drei'
//import Model from "./Models";
import { ModelKuromi } from './Model'

import Loader from "./Loader";

import { Model } from './Island'
import Allight from './Light'

import Button from '@mui/material/Button';

function Scene({Sceneokready}) {
  return (
  <>
    <Allight />
    <Model playaim={Sceneokready} />
    <ModelKuromi
    position={[0, 0, 0]} />
    </>
  );
  
}

function Play({okready}) {

  return (
    <>
       <Environment 
       files={"https://3d.mikuos.com/public/hdr/sky_linekotsi_09.hdr"} 
       ground={{ height: 1, radius: 500, scale: 500 }}/>
    <PerspectiveCamera 
    makeDefault
    position={[0,10,-80]}
    />
      <OrbitControls
      enableRota={okready}
      enableZoom={okready}
      autoRotate={okready}
      enablePan={false}
      autoRotateSpeed={0.3}
      minDistance={20}
      maxDistance={100}
      minPolarAngle={1}
      maxPolarAngle={1.7}
      />
      <Scene Sceneokready={okready} />
      <axesHelper />
      </>
  );
}

let audio = new Audio("https://3d.mikuos.com/public/music/main.mp3")
export default function Webpack() {
  const [ready, set] = useState(false)
  //console.log(ready)
  //const music_pause = () => {
  //  audio.pause()
  //}
  if(ready === true){
    audio.play()
  }
  return (
    <Canvas>
       <Suspense fallback={<Loader />}>
        
       <Html>
       
       <Button className={`${ready ? 'ready' : 'notready'}`} style={{

       }} variant="text" onClick={() => set(true)}>
        <h2 style={{
          marginTop: "-500px",
          width:"300px",
          marginLeft:"-155px",
          animationDuration:"2s",
          animationName:"load",
          animationIterationCount:"infinite",
          animationTimingFunction:"ease",
          zIndex:80
        }}>请点击任意位置查看礼物</h2>
       <div
       
        style={{
          margin: "-500px 0 0 -1000px",
          width: "2000px",
          height: "1000px",
          backgroundColor:"#fff",
          zIndex: 60,
        }}
        >
          
        </div>
        </Button>
       </Html>
       <Play okready={ready} />
      </Suspense>
    </Canvas>
  );
}

//<OrbitControls />
//<SpotLight 
//angle={Math.PI / 20} 
//intensity={10} 
//attenuation={6} 
//anglePower={4}
//position={[0,5, 5]} 
//distance={10} 
//penumbra={1} 
//color={"#fff"}
//castShadow />

//<CameraShake 
//maxYaw={0.01} 
//maxPitch={0.01} 
//maxRoll={0.01} 
//yawFrequency={0.5} 
//pitchFrequency={0.5} 
//rollFrequency={0.4} 
// />