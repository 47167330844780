import { SpotLight} from '@react-three/drei'

export default function Allight() {
    return (
        <>
    <ambientLight intensity={0.2} />
      <SpotLight 
      angle={2} 
      intensity={25} 
      attenuation={50} 
      anglePower={5}
      position={[0,50, 0]} 
      distance={78} 
      penumbra={1}
      color={"#ffb057"}
      castShadow 
      />
      
      <SpotLight 
      angle={2} 
      intensity={3} 
      attenuation={0} 
      anglePower={1}
      position={[-30,-2.5,-5]} 
      distance={78} 
      penumbra={1}
      color={"#fff"}
      castShadow 
      />
      
      <SpotLight 
      angle={2} 
      intensity={3} 
      attenuation={0} 
      anglePower={0.01}
      position={[30,-2.5,5]} 
      distance={78} 
      penumbra={1}
      color={"#fff"}
      castShadow 
      />
      
      <SpotLight 
      angle={5} 
      intensity={20} 
      attenuation={0} 
      anglePower={0.01}
      position={[0,-2.5,70]} 
      distance={78} 
      penumbra={1}
      color={"#fff"}
      castShadow
      />

<SpotLight 
      angle={2} 
      intensity={3} 
      attenuation={50} 
      anglePower={0.01}
      position={[0,40,-50]} 
      distance={0} 
      penumbra={1}
      color={"#fff"}
      castShadow 
      />

<SpotLight 
      angle={10} 
      intensity={3} 
      attenuation={0} 
      anglePower={0.01}
      position={[35,-2.5,-50]} 
      distance={78} 
      penumbra={1}
      color={"#fff"}
      castShadow 
      />

      <SpotLight 
      angle={Math.PI / 1} 
      intensity={5} 
      attenuation={0} 
      anglePower={0.1}
      position={[0,-50, 0]} 
      distance={78} 
      penumbra={1} 
      color={"#fff"}
      castShadow />
          </>
        );
}